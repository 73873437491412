import { Injectable } from "@angular/core";
import {
    GetAllNotificationsGQL,
    GetAllNotificationsQuery,
    MarkReadAllNotificationsGQL
} from '../../services/hobbyts.service';
import { Apollo, MutationResult } from "apollo-angular";
import { Observable } from "rxjs";
import { ApolloQueryResult } from "@apollo/client";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private getAllNotificationsGQL: GetAllNotificationsGQL,
        private apollo: Apollo,
        private markReadAllNotificationsGQL: MarkReadAllNotificationsGQL
    ) { }

    public getAllNotifications(queryVariables: any): Observable<ApolloQueryResult<GetAllNotificationsQuery>> {
        return this.getAllNotificationsGQL
            .watch(queryVariables, { fetchPolicy: 'no-cache' })
            .valueChanges;
    }

    public markAllRead(): Observable<MutationResult<unknown>> {
        return this.apollo
            .mutate({
                mutation: this.markReadAllNotificationsGQL.document
            });
    }
}