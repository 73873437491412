import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, HostBinding, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationDetailComponent } from './norification-detail/notification-detail.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { getSnackBarConfig } from 'src/app/utils/material.utils';
import { calculateTimeOption, generateInitials } from 'src/app/utils/profile.utils';
import { hobbiesTranslations } from 'src/app/shared/constants';
import moment from 'moment';
import { NotificationService } from './notifications.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ScrollingModule } from '@angular/cdk/scrolling';

export enum NotificationTitleType {
  AddFriend = 'add_friend',
  FollowUser = 'follow_user',
  JoinEvent = 'join_event',
  LeaveEvent = 'leave_event',
  AddPlayerEvent = 'add_player_event',
  RemovePlayerEvent = 'remove_player_event',
  RemoveFriend = 'remove_friend',
  UnfollowUser = 'unfollow_user',
  FriendRequestAccept = 'friend_request_accept',
  FriendRequestDecline = 'friend_request_decline',
  InviteEvent = 'invite_event',
  AcceptInvitation = 'accept_invitation',
  DeclineInvitation = 'decline_invitation',
  EventIsDeleted = 'event_is_deleted'
}

@Component({
  selector: 'hb-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatTabsModule, NotificationDetailComponent, ScrollingModule]
})
export class NotificationComponent implements OnInit {
  @HostBinding('class.background-host-page') public backgroundHostPageClass = true;
  public notifications!: any[] | undefined;
  public START_NOTIFICATION_LIMIT = 20;
  public NEXT_NOTIFICATION_LIMIT = 10;

  constructor(
    private notificationService: NotificationService,
    private location: Location,
    private snackBar: MatSnackBar,
    private destroyRef: DestroyRef,
    private elementRef: ElementRef
  ) { }

  public ngOnInit(): void {
    this.getAllNotifications({ count: this.START_NOTIFICATION_LIMIT });
  }

  public navigateToEventsPage(): void {
    this.location.back();
  }

  public markAllRead(): void {
    this.notificationService.markAllRead().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public toggleAllUnread(event: any): void {
    // Unread notifications
    if (event.index === 1) {
      this.getAllNotifications({ isRead: false });
    }
    // All notifications
    if (event.index === 0) {
      this.getAllNotifications({});
    }
  }

  public onScroll(): void {
    const container = this.elementRef.nativeElement.querySelector('.container');
    if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
      this.onScrolledIndexChange();
    }
  }

  public onScrolledIndexChange() {
    if (this.notifications && this.notifications.length > 0) {
      const lastTimestamp = this.notifications[this.notifications.length - 1].createdAt;
      this.getAllNotifications({ count: this.NEXT_NOTIFICATION_LIMIT, lastTimestamp });
    }
  }

  public openSnackBar(message: string, buttonTxt: string): void {
    this.snackBar.open(message, buttonTxt, getSnackBarConfig());
  }

  private getAllNotifications(queryVariables: any): void {
    this.notificationService.getAllNotifications(queryVariables).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: any) => {
      const newNotifications = res.data?.getAllNotifications;
      if (newNotifications) {
        newNotifications.forEach((notification: any) => {
          notification.initial = generateInitials(notification.firstName, notification.lastName);
        });
        
        this.notifications = this.notifications?.length ? [...this.notifications, ...newNotifications] : newNotifications;
        this.localizeMessageAndTitle();
      }
    });
  }

  private localizeMessageAndTitle(): void {
    if (this.notifications?.length) {
      for (const n of this.notifications) {
        switch (n.notification_type) {
          case NotificationTitleType.AddFriend:
            n.message = $localize`:@@addFriendMsg:${n.firstName} ${n.lastName} has sent a friend request`;
            n.title = $localize`:@@friendRequestTitle:Friend Request`;
            break;

          case NotificationTitleType.FollowUser:
            n.message = $localize`:@@followUserMsg:${n.firstName} ${n.lastName} has started following you`;
            n.title = $localize`:@@userFollowTitle:User is Following`;
            break;

          case NotificationTitleType.JoinEvent:
            n.message = $localize`:@@joinEventMsg:${n.firstName} ${n.lastName} joined the event: ${n?.description}`;
            n.title = $localize`:@@joinEventTitle:Join Event`;
            break;

          case NotificationTitleType.LeaveEvent:
            n.message = $localize`:@@leaveEventMsg:${n.firstName} ${n.lastName} left the event: ${n?.description}`;
            n.title = $localize`:@@leaveEventTitle:Left Event`;
            break;

          case NotificationTitleType.AddPlayerEvent:
            n.message = $localize`:@@addPlayerMsg:you are added into the event: ${n.description}`;
            n.title = $localize`:@@playerAddedTitle:Player added in the event`;
            break;

          case NotificationTitleType.RemovePlayerEvent:
            n.message = $localize`:@@removePlayerMsg:Event ${n.hobby}, ${moment(+n.startDate).format('dddd, MMMM Do')}, ${n.startTime} spot canceled`;
            n.title = $localize`:@@playerRemovedTitle:Player removed in the event`;
            break;

          case NotificationTitleType.RemoveFriend:
            n.message = $localize`:@@removeFriendMsg:${n.firstName} ${n.lastName} removed you as a friend`;
            n.title = $localize`:@@removeFriendTitle:Remove friend request`;
            break;

          case NotificationTitleType.UnfollowUser:
            n.message = $localize`:@@unfollowUserMsg:${n.firstName} ${n.lastName} started unfollowing you`;
            n.title = $localize`:@@userUnfollowedTitle:User unfollowed`;
            break;

          case NotificationTitleType.FriendRequestAccept:
            n.message = $localize`:@@friendAcceptMsg:${n.firstName} ${n.lastName} has accepted your friend request`;
            n.title = $localize`:@@friendRequestAcceptedTitle:Friend Request accepted`;
            break;

          case NotificationTitleType.FriendRequestDecline:
            n.message = $localize`:@@friendDeclineMsg:${n.firstName} ${n.lastName} has declined your friend request`;
            n.title = $localize`:@@friendRequestDeclinedTitle:Friend Request declined`;
            break;

          case NotificationTitleType.InviteEvent: {
            const timeOption = calculateTimeOption(+n.startDate, +n.endDate);

            if (timeOption) {
              n.message = $localize`:@@inviteEventMsg:Hello, join my ${hobbiesTranslations[n.hobby] ? hobbiesTranslations[n.hobby] : ''
                } event ${timeOption}`;
            } else {
              n.message = $localize`:@@inviteEventMsgPassed:Hello, my ${hobbiesTranslations[n.hobby] ? hobbiesTranslations[n.hobby] : ''
                } event is finished`;
            }
            n.title = $localize`:@@inviteEventTitle:Event invitation request`;
            break;
          }

          case NotificationTitleType.AcceptInvitation:
            n.message = $localize`:@@acceptInviteMsg:${n.firstName} ${n.lastName} accepted your invitation to ${n.hobby} match on ${moment(+n.startDate).format('dddd, MMMM Do')}, ${n.startTime}`;
            n.title = $localize`:@@invitationAcceptedTitle:Invitation accepted`;
            break;

          case NotificationTitleType.DeclineInvitation:
            n.message = $localize`:@@declineInviteMsg:${n.firstName} ${n.lastName} declined your invitation to ${n.hobby} match on ${moment(+n.startDate).format('dddd, MMMM Do')}, ${n.startTime}`;
            n.title = $localize`:@@invitationDeclinedTitle:Invitation declined`;
            break;

          case NotificationTitleType.EventIsDeleted:
            n.message = $localize`:@@eventIsDeletedMsg:Event ${moment(+n.startDate).format('dddd, MMMM Do')}, ${n.startTime} is deleted`;
            n.title = $localize`:@@DeletedEventTitle:Event is deleted`;
            break;

          default:
            n.message = '';
            break;
        }
      }
    }
  }
}
