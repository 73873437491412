<header class="top-bar" aria-label="header" i18n-aria-label>
  <mat-icon class="arrow-back cursor-pointer" (click)="navigateToEventsPage()">arrow_back</mat-icon>
  <h2 class="page-label" i18n>Notification</h2>
</header>

<div class="top-bar flex-row end">
  <button class="mark-read outline-button" mat-stroked-button (click)="markAllRead()" i18n>MARK ALL READ</button>
</div>

<mat-tab-group class="mb-10" mat-align-tabs="start" (focusChange)="toggleAllUnread($event)">
  <mat-tab i18n-label label="ALL">
    @if (notifications?.length) {
      <div class="container" (scroll)="onScroll()">
        @for (notification of notifications; track notification.id) {
          <hb-notification-detail class="card-container" [notification]="notification"> </hb-notification-detail>
        }
      </div>
    }
  </mat-tab>
  <mat-tab i18n-label label="Unread">
    @if (notifications?.length) {
      @for (notification of notifications; track notification.id) {
        <hb-notification-detail class="card-container" [notification]="notification"> </hb-notification-detail>
      }
    }
  </mat-tab>
</mat-tab-group>
